// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   creditos: [],
//   creditosVencidos: {
//     loading: true,
//     vencidos: [],
//   },
//   creditoEditable: null,
//   loading: true,
//   compromisosRealizados: {
//     compromisos: [],
//     loading: true,
//   },
//   loadingEditar: false,
//   facturas: [],
//   loadingClarisa: false
// };

// export const creditosActivosSlice = createSlice({
//   name: 'creditosActivos',
//   initialState,
//   reducers: {
//     cargarCreditos: (state, action) => {
//       state.creditos = action.payload;
//       state.loading = false;
//     },
//     cargarCreditosDesembolsados: (state, action) => {
//       state.creditosVencidos.vencidos = action.payload;
//       state.creditosVencidos.loading = false;
//     },
//     cargarCompromisos: (state, action) => {
//       state.compromisosRealizados.compromisos = action.payload;
//       state.compromisosRealizados.loading = false;
//     },
//     cargarFacturas: (state, action) => {
//       state.facturas = action.payload;
//       state.compromisosRealizados.loading = false;
//     },
//     cargarCreditoAEditar: (state, action) => {
//       state.creditoEditable = action.payload;
//     },
//     loadingCreditoAEditar: (state, action) => {
//       state.loadingEditar = action.payload;
//     },
//     loadingClarisa: (state, action) => {
//       state.loadingClarisa = action.payload;
//     },
//     actualizarCompromisos: (state, action) => {
//       state.creditoEditable.compromisoCreditos = [...state.creditoEditable.compromisoCreditos, action.payload];
//     },

//     cargarObservacionCredito: (state, action) => {
//       state.creditoEditable.notaCreditos = [...state.creditoEditable.notaCreditos, action.payload.nota];
//     },
//     limpiarCreditoAEditar: (state) => {
//       state.creditoEditable = null;
//     },
    
//   },
// });

// export const {
//   cargarCreditos,
//   cargarCreditosDesembolsados,
//   cargarCreditoAEditar,
//   cargarCompromisos,
//   loadingCreditoAEditar,
//   limpiarCreditoAEditar,
//   cargarObservacionCredito,
//   actualizarCompromisos,
//   cargarFacturas,
//   loadingClarisa
// } = creditosActivosSlice.actions;


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creditos: [],
  creditosVencidos: {
    loading: true,
    vencidos: [],
  },
  creditosEnEstudio: {
    creditos: [],
    loading: true
  },
  creditoEditable: null,
  loading: true,
  compromisosRealizados: {
    compromisos: [],
    loading: true,
  },
  loadingEditar: false,
  facturas: [],
  loadingClarisa: false,
  totalCount: 0,
  currentPage: 1
};

export const creditosActivosSlice = createSlice({
  name: 'creditosActivos',
  initialState,
  reducers: {
    cargarCreditos: (state, action) => {
      // Modificamos para aceptar el nuevo formato de payload
      state.creditos = action.payload.creditos;
      state.totalCount = action.payload.totalCount;
      state.currentPage = action.payload.currentPage;
      state.loading = false;
    },
    // ... resto de tus reducers existentes ...
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    cargarCreditosDesembolsados: (state, action) => {
      state.creditosVencidos.vencidos = action.payload;
      state.creditosVencidos.loading = false;
    },
    cargarCompromisos: (state, action) => {
      state.compromisosRealizados.compromisos = action.payload;
      state.compromisosRealizados.loading = false;
    },
    cargarFacturas: (state, action) => {
      state.facturas = action.payload;
      state.compromisosRealizados.loading = false;
    },
    cargarCreditoAEditar: (state, action) => {
      state.creditoEditable = action.payload;
    },
    loadingCreditoAEditar: (state, action) => {
      state.loadingEditar = action.payload;
    },
    loadingClarisa: (state, action) => {
      state.loadingClarisa = action.payload;
    },
    actualizarCompromisos: (state, action) => {
      state.creditoEditable.compromisoCreditos = [...state.creditoEditable.compromisoCreditos, action.payload];
    },
    cargarObservacionCredito: (state, action) => {
      state.creditoEditable.NotasCredito = [...state.creditoEditable.NotasCredito, action.payload.nota];
    },
    limpiarCreditoAEditar: (state) => {
      state.creditoEditable = null;
    },
    cargarCreditosEnEstudio: (state, action) => {
      state.creditosEnEstudio.creditos = action.payload.creditos;
      state.creditosEnEstudio.loading = false;
    },
    setLoadingCreditosEnEstudio: (state, action) => {
      state.creditosEnEstudio.loading = action.payload;
    }
  },
});

export const {
  cargarCreditos,
  cargarCreditosDesembolsados,
  cargarCreditoAEditar,
  cargarCompromisos,
  loadingCreditoAEditar,
  limpiarCreditoAEditar,
  cargarObservacionCredito,
  actualizarCompromisos,
  cargarFacturas,
  loadingClarisa,
  cargarCreditosEnEstudio,
  setLoadingCreditosEnEstudio,
  setCurrentPage  // Exportamos el nuevo action
} = creditosActivosSlice.actions;