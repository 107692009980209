/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { coleccionCreditosActivos } from '../../../helper/coleccionCreditos';

import TableList from '../../../components/table-list';
import { clasificadorDeCreditos } from '../../../helper/clasificadorDeCreditos';
import { cargarCreditoAEditar } from '../../../store';
import { MAIN_PATH, PATH_NAME } from '../../../constants';
import Iconify from '../../../components/iconify/Iconify';

export function SolicitudesNuevos() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [solicitudes, setSolicitudes] = useState({
    loading: true,
    data: [],
  });
  const { solicitudesNuevos } = useSelector((state) => state.contadorCreditos.creditos);
  // const { creditos } = useSelector((state) => state.creditosActivos);
  const { creditos } = useSelector((state) => state.creditosActivos.creditosEnEstudio);

  useEffect(() => {
    setSolicitudes({
      loading: false,
      data: coleccionCreditosActivos(clasificadorDeCreditos(creditos, solicitudesNuevos)),
    });
  }, []);

  const redireccionarEdicion = (idCredito) => {
    const credito = creditos.filter((credito) => credito.id === idCredito)[0];
    dispatch(cargarCreditoAEditar(credito));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_credito}`, { replace: true });
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cedula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Solicitado',
      name: 'valorSolicitado',
      options: {
        filter: true,
      },
    },
    {
      name: 'Gestionar',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <>
            <Tooltip title="Gestionar" aria-label="Gestionar">
              <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
    {
      name: ' ',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Copiar y Pegar">
            <IconButton
              aria-label="Gestionar"
              onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1].replaceAll('.', ''))}
            >
              <Iconify icon="mdi-light:clipboard-text" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title> Solicitudes de clientes Nuevos</title>
      </Helmet>

      <TableList
        titulo="Clientes Nuevos | Solicitudes de Créditos"
        columnas={columnas}
        loading={solicitudes.loading}
        data={solicitudes.data}
      />
    </>
  );
}
