/* eslint-disable import/order */
import {
  actualizarCompromisos,
  cargarCompromisos,
  cargarCreditos,
  cargarCreditosDesembolsados,
  cargarCreditosEnEstudio,
  cargarFacturas,
  loadingClarisa,
  setLoadingCreditosEnEstudio,
} from './creditosActivosSlice';
import { clarisa, ordenador } from '../../helper/funtions';

import Swal from 'sweetalert2';
import axios from 'axios';
import clienteAxios from '../../helper/axios';

// export const startConsultarCreditosActivos = () => async (dispatch) => {
//   try {
//     const respuesta = await clienteAxios.get('/admin/creditos', {
//       headers: {
//         'Content-Type': 'application/json',
//         'x-token': localStorage.getItem('token') || '',
//       },
//     });
//     dispatch(cargarCreditos(ordenador(respuesta?.data?.creditos)));
//   } catch (error) {
//     console.error(error);
//   }
// };


export const startConsultarCreditosActivos = (page = 1, searchTerm = '') => async (dispatch) => {
  try {
    // Solo incluimos el parámetro search si hay un término
    const searchParam = searchTerm && searchTerm.trim() ? `&search=${encodeURIComponent(searchTerm)}` : '';
    
    const respuesta = await clienteAxios.get(`/admin/creditos?page=${page}&limit=100${searchParam}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });

    dispatch(cargarCreditos({
      creditos: ordenador(respuesta?.data?.creditos),
      totalCount: respuesta?.data?.totalCount,
      currentPage: page
    }));
  } catch (error) {
    console.error(error);
  }
};
export const startConsultarCreditosVencidos = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('/admin/creditos/desembolsados', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });

    dispatch(cargarCreditosDesembolsados(ordenador(respuesta?.data?.creditos)));
  } catch (error) {
    console.error(error);
  }
};

export const startConsultarCompromisos = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('/admin/compromisos', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    dispatch(cargarCompromisos(respuesta.data.compromisos));
  } catch (error) {
    console.error(error);
  }
};
export const startConsultarFacturas = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('/admin/facturas', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });

    dispatch(cargarFacturas(respuesta.data.filter((factura) => factura.creditoId !== null)));
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line consistent-return
// export const startActualizarCreditos = (infoCredito) => async (dispatch) => {
//   try {
//     if (infoCredito.facturar === 'true') {
//       const factura = await dispatch(consultarUltimaFactura());
//       return dispatch(
//         peticionClarisa(
//           clarisa(
//             { ...infoCredito },
//             factura,
//             infoCredito.administracion,
//             infoCredito.plataforma,
//             infoCredito.interes_Corriente
//           ),
//           infoCredito
//         )
//       );
//     }
//     const respuesta = await clienteAxios.put(
//       `/admin/credito/${infoCredito.id}`,
//       {
//         infoCredito,
//       },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           'x-token': localStorage.getItem('token') || '',
//         },
//       }
//     );

//     Swal.fire({
//       title: respuesta?.data?.title,
//       text: respuesta?.data?.mensaje,
//       icon: respuesta?.data?.success === 'error' ? 'error' : 'success',
//       confirmButtonText: 'Ok',
//     });

//     dispatch(startConsultarCreditosActivos());
//   } catch (error) {
//     console.error(error);
//   }
// };

// eslint-disable-next-line consistent-return
export const startActualizarCreditos = (infoCredito) => async (dispatch, getState) => {
  try {
    if (infoCredito.facturar === 'true') {
      const factura = await dispatch(consultarUltimaFactura());
      return dispatch(
        peticionClarisa(
          clarisa(
            { ...infoCredito },
            factura,
            infoCredito.administracion,
            infoCredito.plataforma,
            infoCredito.interes_Corriente
          ),
          infoCredito
        )
      );
    }
    
    const respuesta = await clienteAxios.put(
      `/admin/credito/${infoCredito.id}`,
      {
        infoCredito,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );

    Swal.fire({
      title: respuesta?.data?.title,
      text: respuesta?.data?.mensaje,
      icon: respuesta?.data?.success === 'error' ? 'error' : 'success',
      confirmButtonText: 'Ok',
    });

    // Obtenemos la página actual antes de recargar
    const { currentPage } = getState().creditosActivos;
    dispatch(startConsultarCreditosActivos(currentPage)); // Mantenemos la página actual
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line consistent-return
export const peticionClarisa = (data, infoCredito) => async (dispatch) => {
  const url = 'https://csi.clarisa.co:8443/facturas/rest/api/v1/factura';
  // const urlPrueba = 'https://central.clarisacloud.com:8443/facturacion/rest/api/v1/factura';
  try {
    const respuesta = await axios.post(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('tokenClarisa'),
      },
    });

    if (respuesta.data.success) {
      const respuesta = await clienteAxios.put(
        `/admin/credito/${infoCredito.id}`,
        {
          infoCredito,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-token': localStorage.getItem('token') || '',
          },
        }
      );

      dispatch(startConsultarCreditosActivos());
      Swal.fire({
        title: respuesta?.data?.title,
        text: respuesta?.data?.mensaje,
        icon: respuesta?.data?.success === 'error' ? 'error' : 'success',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (respuesta.data.errores && respuesta.data.errores.errores) {
      const { errores } = respuesta.data.errores;
      Swal.fire({
        title: 'error',
        text: errores[0].errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
  } catch (error) {
    console.error(error);
  }
};

export const agregarCompromiso = (data) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.post(
      `admin/compromiso-credito/${data.id}`,
      {
        infoCredito: data,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );

    Swal.fire({
      title: respuesta.data.title,
      text: respuesta.data.mensaje,
      icon: 'success',
      confirmButtonText: 'Ok',
    });
    dispatch(actualizarCompromisos(data));
  } catch (error) {
    Swal.fire({
      title: 'error',
      text: 'Hubo un error',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
    console.error(error);
  }
};

export const renovarCredito = (infoCredito) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.put(
      `admin/renovacion/${infoCredito.id}`,
      {
        infoCredito,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    Swal.fire({
      title: respuesta?.data?.title,
      text: respuesta?.data?.mensaje,
      icon: respuesta?.data?.success === 'error' ? 'error' : 'success',
      confirmButtonText: 'Ok',
    });

    dispatch(startConsultarCreditosActivos());
    dispatch(cargarCreditos(ordenador(respuesta?.data?.creditos)));
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line consistent-return
export const consultarUltimaFactura = () => async () => {
  try {
    const respuesta = await clienteAxios.get('admin/factura', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    return respuesta.data;
  } catch (error) {
    console.error(error);
  }
};

export const cambioLoadingClarisa = (value) => async (dispatch) => {
  dispatch(loadingClarisa(value));
};



  export const obtenerCreditosEnEstudio = () => async (dispatch) => {
      try {
        dispatch(setLoadingCreditosEnEstudio(true));
        
        const response = await clienteAxios.get('/admin/creditos-estudio', {
          headers: {
            'Content-Type': 'application/json',
            'x-token': localStorage.getItem('token') || '',
          },
        });
        console.log("🚀 ~ obtenerCreditosEnEstudio ~ response:", response);
        
        dispatch(cargarCreditosEnEstudio(response.data));
        
      } catch (error) {
        console.error('Error al obtener créditos en estudio:', error);
      } finally {
        dispatch(setLoadingCreditosEnEstudio(false));
      }
    };